// GlobalStyle.ts
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #121212;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`;
